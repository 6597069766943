import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const Thanks = () => (
  <Layout>
    <Seo title="Thanks" description="Thank you for sending a message" />
    <div className="bg-background py-8">
      <div className="container sm:w:4/5 md:w-7/12 pt-10 bg-white shadow-lg">
        <div className="text-center my-4">
          <h1 className="font-bold text-3xl">
            Thank you for sending a message
          </h1>
          <h2 className="text-xl pt-6 pb-12 px-8">
            I will respond as soon as possible
          </h2>
        </div>
      </div>
    </div>
  </Layout>
)

export default Thanks
